import { StyleSheet } from 'react-native';
import colors from './Colors';


const styles = StyleSheet.create({
  appBg: {
    backgroundColor: colors.softGray
  },
  root: {
    // overflow: 'hidden',
    // position: 'relative',
    // left: 0, right: 0, top: 0, bottom: 0,
  },
  flexCenter: {
    display: 'flex',
    // flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // flexGrow: 1,
    // flexShrink: 1,
    // flexWrap: 'wrap'
  },
  flexVertical: {
    flexDirection: 'column',
  },
  flexAlignTop: {
    alignItems: 'flex-start',
  },
  // https://stackoverflow.com/questions/36284453/react-native-text-going-off-my-screen-refusing-to-wrap-what-to-do
  flexShrink: {
    flexShrink: 1
  },
  fit: {
    flex: 1,
    minHeight: '100%',
    minWidth: '100%',
  },

  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center'
  },

  debug: {
    borderWidth: 1,
    borderColor: 'red',
    borderStyle: 'solid',
  }
});

const circle = r => {
  return {
    width: r,
    height: r,
    borderRadius: r / 2,
  }
};

export default styles;
export {
  circle
}