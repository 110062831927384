import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Dimensions, Text }       from 'react-native';
import { Portal }                             from 'react-native-paper';
import Interactable                           from 'react-native-interactable';

import colors    from '../styles/Colors';


const Screen = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height
};

// switch to web:
// https://github.com/root-two/react-native-drawer
// mentioned here: https://github.com/callstack/react-native-paper/issues/2

// try: https://github.com/arqex/react-interactable
// post: https://blog.bitsrc.io/react-interactable-mobile-gestures-for-the-web-80b6f36f3db0
// port from: https://github.com/wix/react-native-interactable

const theBottomSheet = ({ children, snapTo, snapPoint = 0 }) =>
{
  const [, setWidth] = useState(Screen.width);
  const [height, setHeight] = useState(Screen.height);
  const [currentSnapTo, setCurrentSnapTo] = useState(null);
  const [currentSnapValue, setCurrentSnapValue] = useState(null);
  const iv = useRef(null);
  const snapPoints = [{y: height/2}, {y: height}, {y: 0 + 40}];

  const handleLayout = event => {
    const { nativeEvent: { layout: { width, height } } } = event;
    setWidth(width);
    setHeight(height);
  };

  const handleOnSnap = e => {
    let event = e;
    if('nativeEvent' in e){
      event = e.nativeEvent;
    }
    const { index } = event;
    setCurrentSnapValue(index);
  };

  useEffect(() => {
    if(currentSnapTo !== null && iv.current){
      iv.current.snapTo({index: snapPoint});
    }
  }, [currentSnapTo]);

  useEffect(() => {
    setCurrentSnapTo(snapTo);
  }, [snapTo]);

  let viewAreaHeight = 0;
  if(parseInt(currentSnapValue) > -1) {
    viewAreaHeight = Screen.height - snapPoints[currentSnapValue].y;
  }

  return (
    <Portal>
      <View
        onLayout={handleLayout}
        style={styles.panelContainer}
        pointerEvents={'box-none'}
      >
        <Interactable.View
          ref={iv}
          key={height} // force update
          verticalOnly={true}
          snapPoints={snapPoints}
          boundaries={{top: -300}}
          initialPosition={{y: height}}
          onSnap={handleOnSnap}
        >
          <View style={[styles.panel, { height: height + 300 }]}>
            <View style={styles.handler}><View style={styles.handlerKnop}></View></View>
            <View style={[styles.padd, { height: viewAreaHeight }]}>
              {children}
            </View>
          </View>
        </Interactable.View>
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  panelContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  panel: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    shadowOpacity: 0.1
  },
  padd: {
    // flex: 1,
    padding: 15,
    borderColor:'blue',
    borderWidth:0,
    width: '100%',
  },
  handler: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8
  },
  handlerKnop: {
    width: 30,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#CCC',
    opacity: .5
  }
});

export default theBottomSheet;