const AirtablePlus = require('airtable-plus');

const baseID = 'appzhr6e4HKZwxbqr';
const apiKey = 'keytGCeeB8DrTIf9R';
const airtable = new AirtablePlus({ baseID, apiKey, complex: true });


const timeZone = 'Europe/Madrid';

const TABLES = {
  PARTITS: 'PARTITS',
  RESULTATS: 'RESULTATS',
  EQUIPS: 'EQUIPS',
  JUGADORS: 'JUGADORS',
};

const addGame = async data => {
  return await addGames([data]);
};

const addGames = async data => {
  try {
    const games = data.map(game => { return { fields: game } });
    return await airtable.create(games, { tableName: TABLES.PARTITS, timeZone });
  } catch(e) {
    console.log('e', e, data);
  }
};

const clearGames = async () => {
  try {
    return await airtable.truncate({ tableName: TABLES.PARTITS });
  } catch(e) {
    console.log('e', e);
  }
};

const addMatch = async data => {
  return await addMatches([data]);
};

const addMatches = async data => {
  try {
    const matches = data.map(match => { return { fields: match } });
    return await airtable.create(matches, { tableName: TABLES.RESULTATS, timeZone });
  } catch(e) {
    console.log('e', e, data);
  }
};

const clearMatches = async () => {
  try {
    return await airtable.truncate({ tableName: TABLES.RESULTATS });
  } catch(e) {
    console.log('e', e);
  }
};

const listGames = async () => {
  try {
    return await airtable.read({sort: [{field: 'date', direction: 'asc'}]},{ tableName: TABLES.PARTITS });
  } catch(e) {
    console.log('e', e);
  }
};

const getStats = async (id) => {
  try {
    return await airtable.read({filterByFormula: `game = "${id}"`},{ tableName: TABLES.RESULTATS });
  } catch(e) {
    console.log('e', e);
  }
};


export {
  addGame,
  addGames,
  clearGames,
  addMatch,
  addMatches,
  clearMatches,
  listGames,
  getStats,
};