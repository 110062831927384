import React, { useEffect, useState }         from 'react';
import { Text, StyleSheet, View, ScrollView } from 'react-native';
import { DataTable }                          from 'react-native-paper';

import { getStats } from '../libs/airtable';
import AppStyles    from '../styles/App';


console.log(getStats);

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - 1;
};

const ifCloseToTop = ({ contentOffset }) => {
  return contentOffset.y === 0;
};

const Stats = ({ extraData, selectedGame, onEndScroll, onTopScroll }) =>
{
  const [stats, setStats] = useState([]);

  useEffect(() => {
    (async () => {
      const stats = {};
      if(!!selectedGame){
        const data = await getStats(selectedGame);
        data.map(stat => {
          const team = stat.get('team');
          if(!!stats[team] === false) stats[team] = [];
          stats[team].push({
            id: stat.get('id'),
            number: stat.get('number'),
            name: stat.get('name'),
            points: stat.get('points'),
            P1: stat.get('P1'),
            P2: stat.get('P2'),
            P3: stat.get('P3'),
          });
        });

        // sorting by points
        Object.keys(stats).forEach(team => {
          stats[team].sort((a, b) => { return a.points > b.points ? -1 : 1 });
        });
        setStats(stats);
      }
    })();
  }, [selectedGame]);

  const teams = Object.keys(stats);

  const renderHeaders = tableName => {
    return (
      <>
      <DataTable.Header>
        <DataTable.Title style={[AppStyles.flexCenter, { marginLeft: -15, marginRight: -15 }]}>
          <Text style={[AppStyles.fit, {fontSize:20}]}>{tableName}</Text>
        </DataTable.Title>
      </DataTable.Header>
      <DataTable.Header>
        <DataTable.Title style={[styles.rowNumber]}>#</DataTable.Title>
        <DataTable.Title>Name</DataTable.Title>
        <DataTable.Title style={[styles.rowNumber]} numeric right>P</DataTable.Title>
        <DataTable.Title style={[styles.rowNumber]} numeric right>P1</DataTable.Title>
        <DataTable.Title style={[styles.rowNumber]} numeric right>P2</DataTable.Title>
        <DataTable.Title style={[styles.rowNumber]} numeric right>P3</DataTable.Title>
      </DataTable.Header>
      </>
    );
  };

  const renderPlayer = ({ id, number, name, points, P1, P2, P3 }) => {
    return (
      <DataTable.Row>
        <DataTable.Cell style={[styles.rowNumber]}>{number}</DataTable.Cell>
        <DataTable.Cell>{name}</DataTable.Cell>
        <DataTable.Cell style={[styles.rowNumber]} numeric right>{points}</DataTable.Cell>
        <DataTable.Cell style={[styles.rowNumber]} numeric right>{P1}</DataTable.Cell>
        <DataTable.Cell style={[styles.rowNumber]} numeric right>{P2}</DataTable.Cell>
        <DataTable.Cell style={[styles.rowNumber]} numeric right>{P3}</DataTable.Cell>
      </DataTable.Row>
    );
  };

  let score1 = null;
  let score2 = null;
  if(!!extraData){
    if(teams[0] === extraData.local){
      score1 = extraData.localScore;
      score2 = extraData.visitantScore;
    } else {
      score1 = extraData.visitantScore;
      score2 = extraData.localScore;
    }
  }

  return (
    <ScrollView
      bounces={false}
      scrollEventThrottle={20}
      onScroll={({ nativeEvent }) => {
        if(ifCloseToTop(nativeEvent)){
          onTopScroll();
        }
        if (isCloseToBottom(nativeEvent)) {
          onEndScroll();
        }
      }}
      style={[AppStyles.fit]}
    >
      <View style={[styles.rootLocal]}>
        <DataTable>
        {renderHeaders(`${teams[0]}, ${score1}`)}
        {teams.length > 0 && Object.keys(stats[teams[0]]).map(index => {
          const player = stats[teams[0]][index];
          return renderPlayer(player);
        })}
        </DataTable>
      </View>
      <DataTable>
      {renderHeaders(`${teams[1]}, ${score2}`)}
      {teams.length > 0 && Object.keys(stats[teams[1]]).map(index => {
        const player = stats[teams[1]][index];
        return renderPlayer(player);
      })}
      </DataTable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: '100%',
    minWidth: '100%'
  },
  rootLocal: {
    // width: '100%'
    marginBottom: 15
  },
  rootVisitant: {
    // width: '100%'
  },
  tableRow: {
    padding: 10
  },
  rowNumber: {
    width: 27,
    maxWidth: 27,
  },
  rowName: {
    // width: '100%',
  }
});

export default Stats;