import React                             from 'react';
import { View, StatusBar, SafeAreaView } from 'react-native';
import { Provider as PaperProvider }     from 'react-native-paper';

import Games     from './components/Games';
import AppStyles from './styles/App';


const App = () => {
  return (
    <PaperProvider>
      <View style={[AppStyles.fit, AppStyles.appBg]}>
        <StatusBar barStyle="default" />
        <SafeAreaView style={[AppStyles.fit]}>
          <Games />
        </SafeAreaView>
      </View>
    </PaperProvider>
  );
};

export default App;
