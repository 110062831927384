import React, { useState, useEffect }                         from 'react';
import { StyleSheet, Text, View, FlatList, TouchableOpacity } from 'react-native';
import RNPickerSelect                                         from 'react-native-picker-select';
import chroma                                                 from 'chroma-js';
import shuffle                                                from 'shuffle-array';

import { listGames }      from 'shared/src/libs/airtable';
import { default as xxx } from 'shared/src/libs/moment';

import AppStyles, { circle } from '../styles/App';
import colors                from '../styles/Colors';
import BottomSheet           from './BottomSheet';
import Stats                 from './Stats';

const moment = xxx.default;

const getFirstLetters = (name, words = 3) => {
  return name
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.substr(0, 1).toUpperCase()
        : word.substr(0, 1).toLowerCase()
    )
    .slice(0, words)
    .join(' ');
};

const TeamLogo = ({ letter, color }) => {
  return (
    <View style={[styles.teamLogo, { backgroundColor: color }]}>
      <Text style={styles.teamLogoLetters}>{letter}</Text>
    </View>
  );
};

const Item = props => {
  const { id, local, visitant, localScore, visitantScore, date, selectedTeam, onSelect, localColor, visitantColor } = props;
  const isSelected = local === selectedTeam || visitant === selectedTeam ;
  const gamePlayed = !!localScore ;

  return (
    <TouchableOpacity onPress={() => { if(gamePlayed) onSelect(props); }}>
      <View key={id} style={[styles.item, isSelected ? styles.itemSelected : styles.itemUnselected]}>
        <View style={[AppStyles.flexCenter, styles.padd]}>
          <View style={[AppStyles.flexCenter, styles.local]}>
            <TeamLogo letter={getFirstLetters(local)} color={localColor} />
            <View style={[AppStyles.flexShrink, styles.padd]}>
              <Text style={[AppStyles.right, selectedTeam === local ? styles.teamSelected : styles.itemUnselected]}>{local}</Text>
            </View>
          </View>
          <View style={[styles.result]}>
            <View style={[AppStyles.flexCenter]}>
              <Text style={[styles.teamLogoText]}>{localScore}</Text>
              <View style={[styles.vs]}><Text style={styles.dateText}>{gamePlayed ? 'vs' : date}</Text></View>
              <Text style={[styles.teamLogoText]}>{visitantScore}</Text>
            </View>
          </View>
          <View style={[AppStyles.flexCenter, styles.visitant]}>
            <View style={[AppStyles.flexShrink, styles.padd]}>
              <Text style={[AppStyles.left,selectedTeam === visitant ? styles.teamSelected : styles.itemUnselected]}>{visitant}</Text>
            </View>
            <TeamLogo letter={getFirstLetters(visitant)} color={visitantColor} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Games = () => {
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [snapTo, setSnapTo] = useState(null);
  const [snapPoint, setSnapPoint] = useState(0);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedGame, setSelectedGame] = useState(null);
  const [extraData, setExtraData] = useState(null);

  useEffect(() => {
    (async () => {
      const aGames = [];
      const teams = {};
      const dbGames = await listGames();

      let colorToTeam = {};
      let COLORS = chroma
        .scale(['#FF6E40', '#FFD740', '#00B8D4'])
        .mode('lch')
        .colors(dbGames.length / 2);
      shuffle(COLORS);

      dbGames.forEach(game => {
        const id = game.get('id');
        const local = game.get('local');
        const visitant = game.get('visitant');
        teams[local] = 1;
        teams[visitant] = 1;

        if(!!(local in colorToTeam) === false){ colorToTeam[local] = COLORS.pop(); }
        if(!!(visitant in colorToTeam) === false){ colorToTeam[visitant] = COLORS.pop(); }

        aGames.push({
          id,
          key: id,
          local,
          visitant,
          localScore: game.get('localScore'),
          visitantScore: game.get('visitantScore'),
          date: moment(game.get('date')).format('M/D ddd H:mm'),
          localColor: colorToTeam[local],
          visitantColor: colorToTeam[visitant],
        });
      });
      setGames(aGames);
      setTeams(Object.keys(teams));
    })();
  }, []);

  const handleSelectGame = data => {
    const { id } = data;
    setSelectedGame(id);
    setSnapPoint(0);
    setSnapTo(!snapTo);
    setExtraData(data);
  };

  return (
    <View style={[AppStyles.fit]}>
      <View style={[{ width: '100%' }]}>
        <View style={[styles.picker, styles.padd]}>
          <RNPickerSelect
            placeholder={{label:'Team …', value: 'xxx'}}
            value={selectedTeam}
            onValueChange={value => setSelectedTeam(value)}
            items={teams.map(team => ({ label: team, value: team }))}
          />
        </View>
      </View>

      <FlatList
        extraData={{selectedTeam}}
        data={games}
        renderItem={({item}) => <Item {...item} onSelect={handleSelectGame} selectedTeam={selectedTeam} />}
        keyExtractor={item => item.id}
      />

      <BottomSheet
        snapTo={snapTo}
        snapPoint={snapPoint}
      >
        <Stats
          extraData={extraData}
          selectedGame={selectedGame}
          onEndScroll={() => {
            // setSnapPoint(2);
            // setSnapTo(!snapTo);
          }}
          onTopScroll={() => {}}
        />
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    margin: 5,
    marginLeft: 10,
    marginRight: 10,
    borderWidth: 1,
    borderColor: 'transparent',

    borderRadius: 4,
    backgroundColor: colors.white,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: .05,
    shadowRadius: 8,
  },
  itemSelected: {
    borderColor: colors.blue
  },
  itemUnselected: {
  },
  teamSelected: {
  },
  teamUnselected: {
  },
  padd: {
    padding: 15
  },
  picker: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  local: {
    width: '40%',
  },
  result: {
    width: '20%'
  },
  vs: {
    // ...circle(17),
    // backgroundColor: colors.blue,
  },
  dateText: {
    fontSize: 10,
    textAlign: 'center',
    // color: colors.white,
    // lineHeight: 16
  },
  visitant: {
    width: '40%'
  },

  teamLogo: {
    ...circle(44),
    backgroundColor: colors.blue,
    borderWidth: 1,
    borderColor: '#DDD',
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamLogoLetters: {
    letterSpacing: -1,
    color: colors.white,
    // textTransform: 'capitalize'
  },
  teamLogoText: {
    fontSize: 20,
  }
});

export default Games;