const momentTZ = require('moment-timezone');
const moment = require('moment');
require('moment/locale/es');

const locale = 'es';
moment.locale(locale);
momentTZ.updateLocale(locale, moment.localeData()._config);
momentTZ.tz.setDefault('Europe/Madrid');

module.exports = {
  default: momentTZ
};